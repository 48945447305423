<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    class="mt-2"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      class="columns is-multiline is-mobile"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="column is-6-tablet is-12-mobile">
        <b-select-validation
          v-model="myCategory.parent_id[0].id"
          rules="required"
          :label="$tc('tree', 1)"
          :disabled="disableInfos"
        >
          <template>
            <option
              v-for="opt in mappedCategories"
              :key="opt.id"
              :value="opt.id"
            >
              {{ opt.label }}
            </option>
          </template>
        </b-select-validation>
      </div> 

      <div class="column is-6-tablet is-6-mobile">
        <b-input-validation
          v-model="myCategory.rank"
          :disabled="disableInfos"
          rules="required"
          vid="rank"
          :label="$t('rank')"
          input-type="number"
          :placeholder="placeholders.number['1']"
        />
      </div>

      <b-tabs
        type="is-boxed"
        class="column is-12 mb-0 paddingless"
      >
        <b-tab-item
          v-for="(site, i) in sites"
          :key="'tab' + site.id"
          :value="`${site.id}`"
        >
          <template slot="header">
            <img
              :src="'/img/sites/' + site.id + '.svg'"
              width="20"
              height="20"
              alt
              class="mr-2"
            >
            {{ site.label }}
          </template>

          <b-tabs
            :class="$device.mobile ? 'paddingless' : 'paddingless-right'"
            :vertical="!$device.mobile"
          >
            <b-tab-item
              v-for="(lang, j) in site.languages"
              :key="'lang-' + lang.iso"
              header-class="mb-4"
            >
              <template slot="header">
                <img
                  :src="'/img/langs/' + lang.iso + '.svg'"
                  width="18"
                  height="18"
                  class="mr-2"
                >
                {{ getLanguageLabel(site.id, lang.iso) }}
              </template>
              <div style="max-width: fit-content">
                <div class="columns is-multiline is-mobile ml-2">
                  <b-input-validation
                    v-model="sites[i]['languages'][j]['category']"
                    class="column is-6-tablet is-12-mobile"                   
                    :placeholder="placeholders.blog.category.name"
                    :label="$tc('category', 1)"
                    maxlength="50"
                    counter="50"
                    :vid="'name' + site.id + lang.iso"
                    :disabled="disableInfos"
                    @blur="validName($event, i, site.id, j, lang.iso, false)"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['permalink']"
                    class="column is-6-tablet is-12-mobile"
                    :placeholder="placeholders.blog.category.permalink"
                    :disabled="
                      (action != 'create' &&
                        sites[i]['languages'][j]['permalink'] &&
                        !permalinkChangedFromInput.includes(`${i}_${j}`)) ||
                        disableInfos
                    "
                    :label="$t('permalink')"
                    :rules="getPermalinkValidation(site.id, lang.iso)"
                    validation-mode="lazy"
                    maxlength="50"
                    counter="50"
                    :vid="'permalink' + site.id + lang.iso"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['h1']"
                    class="column is-6-tablet is-12-mobile"
                    :placeholder="placeholders.blog.category.h1"
                    :label="'h1' + ' (' + $t('optional') + ')'"
                    maxlength="80"
                    counter="80"
                    :vid="'h1' + site.id + lang.iso"
                    :disabled="disableInfos"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['meta_title']"
                    class="column is-6-tablet is-12-mobile"
                    :placeholder="placeholders.blog.category.meta_title"
                    :label="$t('meta_title') + ' (' + $t('optional') + ')'"
                    maxlength="90"
                    counter="90"
                    :vid="'meta_title' + site.id + lang.iso"
                    :disabled="disableInfos"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['meta_description']"
                    class="column is-12"
                    :placeholder="placeholders.blog.category.meta_description"
                    input-type="textarea"
                    :vid="'meta_description' + site.id + lang.iso"
                    :label="$t('meta_description') + ' (' + $t('optional') + ')'"
                    rows="2"
                    auto-grow
                    :disabled="disableInfos"
                    maxlength="300"
                    counter="300"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['description_short']"
                    class="column is-12"
                    :placeholder="placeholders.blog.category.description_short"
                    input-type="textarea"
                    :vid="'description_short' + site.id + lang.iso"
                    :label="$t('description_short') + ' (' + $t('optional') + ')'"
                    rows="2"
                    auto-grow
                    :disabled="disableInfos"
                    maxlength="512"
                    counter="512"
                  />

                  <b-input-validation
                    v-model="sites[i]['languages'][j]['description']"
                    class="column is-12 pb-0"
                    :placeholder="placeholders.blog.category.description"
                    input-type="textarea"
                    :vid="'description' + site.id + lang.iso"
                    :disabled="disableInfos"
                    :label="$t('description') + ' (' + $t('optional') + ')'"
                    rows="10"
                    auto-grow
                  />
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </b-tab-item>
      </b-tabs>
      <b-button
        type="is-primary"
        :disabled="save_loading || disableInfos"
        :loading="save_loading"
        tag="input"
        native-type="submit"
        :value="$t('save')"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
    sitesFilters: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    level: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      mappedCategories: [],
      save_loading: false,
      myCategory: null,
      disableInfos: false,
      categoryId: this.category.id,
      sitesData: this.sites,
      permalinkChangedFromInput: []
    };
  },
  created() {
    this.filterCats("");
    if (this.category) {
      this.myCategory = { ...this.category };
      if (this.category.parent_id != null) {
        this.myCategory.parent_id = [
          this.mappedCategories.find(c => c.id == this.category.parent_id)
        ];
      } else {
        this.myCategory.parent_id = [
          {
            id: "0",
            label: this.$i18n.t("homepage"),
            cls: "pl-0 has-text-weight-bold",
            disabled: this.level == 1 || this.level == -1 ? false : true
          }
        ];
      }
    }
    if (this.action === "read") {
      this.disableInfos = true;
    }
  },
  methods: {
    beforeAddParent(row) {
      return !row.disabled;
    },
    getLanguageLabel(site_id, lang_iso) {
      let site = this.sitesFilters.find(s => s.id == site_id);
      if (site) {
        let lang = site.languages.find(l => l.iso == lang_iso);
        if (lang) return lang.label;
      }
      return lang_iso;
    },
    changeParent() {
      this.myCategory.parent_id = [
        this.myCategory.parent_id[this.myCategory.parent_id.length - 1]
      ];
    },
    filterCats(search) {
      let mapped =
        this.action === "u"
          ? []
          : [
              {
                id: "0",
                label: this.$i18n.t("homepage"),
                cls: "pl-0 has-text-weight-bold",
                disabled: this.level == 1 || this.level == -1 ? false : true
              }
            ];
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id != this.category.id) {
          mapped.push({
            id: this.categories[i].id,
            label: this.categories[i].label,
            cls: "pl-0 has-text-weight-bold",
            disabled: this.level == 2 || this.level == -1 ? false : true,
            level: 1
          });
        }
      }

      let filtered = mapped.filter(option => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });

      this.mappedCategories = filtered;
    },
    getPermalinkValidation(site_id, lang_iso) {
      let required = site_id === 1 && lang_iso === "fr" ? "|required" : "";
      let valid_permalink = `valid_blog_category_permalink:${site_id}/${lang_iso}/check${
        this.categoryId != null ? "/" + this.categoryId : ""
      }`;
      return this.action == "create" ? valid_permalink : "";
    },
    validName(event, site_index, site_id, lang_index, lang_iso) {
      if (
        this.action === "create" ||
        !this.sites[site_index]["languages"][lang_index].permalink ||
        this.permalinkChangedFromInput.includes(`${site_index}_${lang_index}`)
      ) {
        let url = `blog/categories/permalink/${encodeURIComponent(
          event.target.value.split("/").join(" ")
        )}/${site_id}/${lang_iso}/${
          this.categoryId != null ? this.categoryId : ""
        }`;
        this.$axios
          .get(url)
          .then(res => {
            this.$set(
              this.sites[site_index]["languages"][lang_index],
              "permalink",
              res.data.permalink
            );
            this.permalinkChangedFromInput.push(`${site_index}_${lang_index}`);
          })
          .catch(e => {
            let permalink =
              e.response && e.response.data && e.response.data.permalink;

            this.$set(
              this.sites[site_index]["languages"][lang_index],
              "permalink",
              permalink
            );
            const code = parseInt(e.response && e.response.status);
            if (code === 409) {
              let obj = {};
              obj["permalink" + site_id + lang_iso] = this.$i18n.t(
                "x_already_exists",
                {
                  field: this.sites[site_index]["languages"][lang_index][
                    "permalink"
                  ]
                }
              );
              this.$refs.observer.setErrors(obj);
            }
          });
      }
    },
    save() {
      this.save_loading = true;
      let data = {
        category_parent_id: this.myCategory.parent_id[0].id == "0" ? null : this.myCategory.parent_id[0].id,
        active: this.myCategory.active,
        rank: this.myCategory.rank,
        trans: []
      };
      let i;
      for (i = 0; i < this.sites.length; i++) {
        let j;
        for (j = 0; j < this.sites[i].languages.length; j++) {
          let languages = { ...this.sites[i]["languages"][j] };
          languages.lang_iso = this.sites[i]["languages"][j].iso;
          delete languages.iso;
          delete languages.label;
          data.trans.push(languages);
        }
      }
      let method = this.action == "update" ? "PUT" : "POST";
      let endpoint = "blog/categories";
      if (this.action == "update") endpoint += `/${this.categoryId}`;
      this.$axios({
        method: method,
        url: endpoint,
        data: data
      })
        .then(() => {
          if (this.action == "create") {
            this.$root.notify(this.$t("successfully_saved"), "is-success");
          } else {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          }
          this.$router.push("/blog/categories");
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.save_loading = false));
    }
  }
};
</script>
