<template>
  <Page
    has-actions
    :subtitle="subtitle"
    :loading="category_loading"
  >
    <b-switch
      v-if="category"
      slot="actions"
      :disabled="action == 'create'"
      :value="category.active"
      type="is-success"
      @input="activateOrDesactivateCategory"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card content-class="">
      <div
        v-if="category_loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="category_loading"
          :is-full-page="false"
        />
      </div>
      <CategoryForm
        v-else
        :key="category ? category.id : 0"
        :categories="categories"
        :category="category"
        :level="category.level"
        :index="index"
        :sites="sites"
        :sites-filters="sitesFilters"
        :action="action"
      />
    </Card>
  </Page>
</template>

<script>
import CategoryForm from "@/components/forms/Blog/Category";

export default {
  components: {
    CategoryForm
  },
  data() {
    return {
      subtitle: "",
      action: "",
      category: {
        active: true
      },
      sites: [],
      categories: [],
      category_loading: false,
      index: -1
    };
  },
  created() {
    if (this.$route.params.category_id) {
      if (this.$can("update", "catalog")) {
        this.action = "update";
        this.updateTitle(this.$t("edit_noun"));
      } else if (this.$can("read", "catalog")) {
        this.action = "read";
        this.updateTitle(this.$tc("category", 1));
      }
      this.getCategory(this.$route.params.category_id);
    } else {
      this.action = "create";
      this.updateTitle(this.$t("new_category"));
      this.getCategory(0);
    }
  },
  methods: {
    activateOrDesactivateCategory(){
      if (this.category.active) {
        this.$axios
        .delete("blog/categories/" + this.category.id)
          .then(() => {
            this.category.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch(e => this.clientError(e));
      } else {
        this.$axios
        .patch("blog/categories/" + this.category.id + "/restore")
          .then(() => {
            this.category.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
          .catch(e => this.clientError(e));
      }
    },
    getCategory(category_id) {
      this.category_loading = true;
      this.$axios
        .get("blog/categories/" + category_id)
        .then(res => {
          if (category_id) {
            this.category = res.data.category;
            this.sites = res.data.category.trans;
            this.subtitle = res.data.category.category;
            this.index = 1;
          } else {
            this.setSites(res.data.filters.sites);
          }
          this.categories = res.data.filters.categories;
          this.sitesFilters = res.data.filters.sites;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.category_loading = false));
    },
    setSites(sites) {
      for (let i = 0; i < sites.length; i++) {
        let site = sites[i];
        let languages = [];
        for (let j = 0; j < site.languages.length; j++) {
          languages.push({
            ...site.languages[j],
            site_id: site.id,
            h1: null,
            name: null,
            paermalink: null,
            meta_title: null,
            meta_description: null,
            description: null
          });
        }
        this.sites.push({
          id: site.id,
          label: site.label,
          type: site.type,
          languages: languages
        });
      }
    }
  }
};
</script>
